import React, { Component } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

class Contact extends Component {
  render() {
    return (
      <div className="page-container contact">
        <div className="triangle">
        <ScrollAnimation animateIn="zoomIn">
          <h1>Contact</h1>
          <p>Don't hesitate to reach out. I'm just a message away to discuss your web development needs.Send your message to <a href="mailto:florentina@florentinasultan.co.uk">florentina@florentinasultan.co.uk</a></p>
          {/* <form id="contact-form" name="contact-form" onSubmit={this.handleSubmit}>
              <div className="form-group">
                  <label htmlFor="name">
                  Your Name:</label> <input type="text" name="name" className="form-control"  value={name} onChange={this.handleChange} />
                  
              </div>
              <div className="form-group">
                  <label htmlFor="email">
                  Your Email: </label> <input type="email" name="email" className="form-control" value={email} onChange={this.handleChange} />
                  
              </div>
              <div className="form-group">
                  <label htmlFor='message'>
                  Message:</label> <textarea name="message" className="form-control" value={message} onChange={this.handleChange} />
              </div>
              <div className="form-group">
              <button type="submit" className="btn primary">Submit</button>
              </div>
          </form> */}
          </ScrollAnimation>
        </div>
      </div>
    );
  }
}

export default Contact;