// @flow
import React, { Component } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import florentina from '../assets/images/florentina.png';

class Home extends Component {

    render() {
        return (
            <div className="page-container home resume-extended">
                <div className="container">
                <div className='content-right'>
                        <div>
                            <ScrollAnimation animateIn="zoomIn">
                            <p>
                                <h1>Florentina Sultan</h1>
                                <span>Shopify/Frontend Developer</span>
                            </p>
                            </ScrollAnimation>
                            <div className='animated'>
                                <div className="full-width">
                                    <img className="" src={florentina} alt="Sultan Florentina - Frontend Developer"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content-resume">
                        <div className="text">
                            <ScrollAnimation animateIn="zoomIn">
                            <div className="text border--left">
                                <div>I'm a self-motivated and ambitious Frontend/Web Developer, highly focused with a
                                comprehensive knowledge of designing and developing the layout, visual appearance
                                and usability of a website.</div>
                                <div>My expertise spans a range of prominent web development platforms, including Shopify, WordPress, BigCommerce, Magento, and React headless solutions
                                </div>
                                <br></br>
                                <h3>Experience</h3>
                                <strong>October 2021 - Present</strong><br></br>
                                <strong>Flo Dev Solutions / Craiova, Romania/Remote</strong>
                                <div>Shopify and Frontend Developer</div>
                                <label>Shopify and Frontend Developer responsibilities and duties:</label>
                                <ul>
                                    <li>Coding custom themes based on the provided design</li>
                                    <li>Expanding existing themes based on the provided design</li>
                                    <li>Building custom apps to enhance the basic Shopify functionality</li>
                                    <li>According to the clients requirements and needs expand or enhance the design and functionality of their current website</li>
                                </ul>

                                <strong>November 2015 - October 2021 </strong><br></br>
                                <strong>Unified / Richmond, London</strong>
                                <div>WordPress/Magento 1/Magento 2 frontend developer</div>
                                <label>WordPress Developer responsibilities and duties:</label>
                                <ul>
                                    <li>Building custom themes</li>
                                    <li>Building custom plugins</li>
                                    <li>Edit or extend plugins functionality</li>
                                    <li>Edit and improve styles and Wordpress functionality</li>
                                </ul>
                                <label>Magento 1 & 2 Frontend Developer responsibilities and duties:</label>
                                <ul>
                                    <li>Coding from scratch custom themes based on the provided design</li>
                                    <li>Building custom modules to enhance the basic functionality</li>
                                    <li>According to the clients requirements and needs expand or enhance the design and functionality of their current web site</li>
                                </ul>
                                <strong>August 2013 - March 2014</strong><br></br>
                                <strong> WordPress developer - Craiova, Romania</strong>
                                
                                <div>Website maintenance and content editing with
                                WordPress
                                Styling css and redesign of the sites.
                                March 2011 - January 2013
                                Karma SRL - Clothing Factory Administrator</div>
                                <strong>March 2008 – March 2011</strong><br></br>
                                <strong>Autogrill Spa - Italy</strong>
                                <div>
                                QA tester - Testing the till software for the Autogrill.spa</div>
                                <br></br>
                                <br></br>
                                <h3>Education</h3>
                                <strong>2008 – 2011 - Spiru Haret University</strong>
                                <div>Accounting and Management Information Systems
                                (Craiova, Romania)</div>
                                <strong>2000 – 2004 – "Stefan Odobleja" High School</strong>
                                <div>Mathematics and Computer Science
                                professional certificate : Programming – medium level</div>
                                
                                <br></br>
                                <br></br>
                                <h3>Skills</h3>
                                <ul>
                                <li>Experience in building and maintain of Shopify websites</li>
                                <li>Experience in customise any BigCommerce and Shopify themes to meet the
                                clients requirements</li>
                                <li>Experience in building and maintain of magento 1/2 websites, mostly
                                frontend but worked with backend modules also</li>
                                <li>Experience in building from scratch and maintain of WordPress themes, and
                                plugins</li>
                                <li>Building static websites in ReactJs</li>
                                <li>Working with git - GitHub, Bitbucket</li>
                                <li>Coding any design/PDF/Figma to HTML/CSS/JS</li>
                                <li>SEO</li>
                                </ul>
                                
                                </div>                            
                            </ScrollAnimation>
                        </div>
                    </div>
                   
                </div>
            </div>
          );
    }
}

export default Home;