import React, { Component } from 'react';

class Card extends Component {
    render(){
        return (
            <div className="slide-img">
                <img src={this.props.cardImage} alt={this.props.cardName}/>
                <div className="card__head">
                    <span>{this.props.cardName}</span>
                    <span>{this.props.cardLinkCMS}</span>
                    <a href={this.props.cardLink} target="_blank" rel="noopener noreferrer">{this.props.cardLinkText}</a>
                </div>
            </div>
        );
    }
}

export default Card;