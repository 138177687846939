import React, { Component } from 'react';
import Card from './PortfolioCard';
import OpenSource from './PortfolioOpenSource';
import ScrollAnimation from 'react-animate-on-scroll';
import anuntulmeu from '../assets/images/anuntulmeu.png';
import blr21 from '../assets/images/blr21.png';
import constr from '../assets/images/Figma_Design_Constr.png';
import ratingStarsFE from '../assets/images/rating-stars-fe.png';
import ratingStars from '../assets/images/star-rating.png';
import couponiada from '../assets/images/coupiniada.png';

class PortfolioPage extends Component {
    render() {
        return (
            
            <div className="page-container portfolio triangle">
                <div className="personal-work">
                    <h1>Portfolio</h1>
                </div>
                <ScrollAnimation animateIn='zoomIn'>
                    <div className="cards-images">
                        <Card cardImage={constr} cardName="Figma - Business Presentation Website" cardLink="https://www.figma.com/file/ioXPzIXHKqBaZhQakENFsz/Constructions-Portfolio?type=design&node-id=8%3A3&t=AfANJR7YoXzzDgZH-1" cardLinkText="See Figma" cardLinkCMS="Design/Figma" />
                        <Card cardImage={anuntulmeu} cardName="anuntulmeu.ro" cardLink="https://www.anuntulmeu.ro/" cardLinkText="See Website" cardLinkCMS="WordPress/Theme Customization/Custom Plugin/Redesign/SEO" />
                        <Card cardImage={blr21} cardName="BLR21- Cleaning Service" cardLink="https://blr21cleaningservice.co.uk/" cardLinkText="See Website" cardLinkCMS="WordPress/Theme Customization" />
                        <Card cardImage={couponiada} cardName="Couponiada - WordPress" cardLink="https://couponiada.ro/" cardLinkText="See Website" cardLinkCMS="Redesign + SEO Optimization" />
                    </div>
                </ScrollAnimation>

                <ScrollAnimation animateIn='zoomIn'>
                    <div className="personal-work">
                        <h3>Open Source/GIT</h3>
                    </div>
                </ScrollAnimation>
                <OpenSource cardImage={ratingStars} cardImage2={ratingStarsFE} cardName="Rating Stars - WordPress Plugin" cardLink="https://github.com/florentinaa/rating-stars" cardLinkText="See github page" cardLinkCMS="WordPress Plugin" />

            </div>
          );
    }
}

export default PortfolioPage;