// @flow
import React, { Component } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import ReactNative from '../assets/images/react-native.svg';
import Javascript from '../assets/images/javascript.svg';
import Sass from '../assets/images/sass.svg';
import Magento from '../assets/images/magento.svg';
import WordPress from '../assets/images/wordpress.svg';
import {Link} from "react-router-dom";


class Resume extends Component {
    componentDidMount() {
        document.querySelectorAll('.link').forEach(link => {
           link.addEventListener('mouseover', mouseOver );
           link.addEventListener('mouseout', mouseOut );
        });

        function mouseOver(e) {
           const hoverClass = e.target.classList.value.replace(' link', '');
           document.querySelector('.' + hoverClass + '-link').style.color = '#9B4169';
           document.querySelector('.' + hoverClass + '-link').style.fontWeight = '400';
        }
        function mouseOut(e) {
            const hoverClass = e.target.classList.value.replace(' link', '');
            document.querySelector('.' + hoverClass + '-link').style.color = null;
            document.querySelector('.' + hoverClass + '-link').style.fontWeight = null;
         }
    }
    render() {
        return (
            <div className="page-container resume">
                <div className="triangle">
                    <div className="content-left">
                        <ScrollAnimation animateIn="zoomIn">
                            <h1>Resume</h1>
                            <div className="text">
                                <h3>Skills</h3>
                                <ul>
                                    <li>HTML/HTML5</li>
                                    <li className='sass-link'>CSS/SCSS/LESS</li>
                                    <li>Tailwind</li>
                                    <li className='react-link'>React</li>
                                    <li className='javascript-link'>jQuery/React/Javascript</li>
                                    <li>PHP/MySQL</li>
                                    <li className='magento-link wordpress-link'>Shopify/WordPress/Magento/BigCommerce/Storyblok</li>
                                    <li>Vagrant/Warden/MAMP/XAMP/Valet</li>
                                    <li>GIT - Github/Bitbucket</li>
                                    <li>Figma/Affinity Designer/Photoshop/Zeplin</li>
                                </ul>
                            </div>
                        </ScrollAnimation>
                    </div>
                    <div className='content-right'>
                        <ScrollAnimation animateIn="fadeIn">
                            <Link className="button" to="/extended-resume">
                                <div className="resume">
                                    <span>Check my CV</span>
                                </div>
                            </Link>
                        </ScrollAnimation>
                        <ScrollAnimation animateIn="zoomIn">
                            <div className="rotate-icons">
                                <img src={ReactNative} alt="React Native" className='react link' />
                                <img src={Javascript} alt="Javascript" className='javascript link' />
                                <img src={Sass} alt="Sass" className='sass link' />
                                <img src={Magento} alt="Magento" className='magento link' />
                                <img src={WordPress} alt="WordPress" className='wordpress link'/>
                            </div>
                        </ScrollAnimation>
                    </div>
                </div>
            </div>
          );
    }
}

export default Resume;