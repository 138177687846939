// @flow
import React, { Component } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import florentina from '../assets/images/florentina.png';

class Home extends Component {

    render() {
        return (
            <div className="page-container home">
                <div className="container triangle">
                    <div className="content-left ">
                        <div className="text">
                            <ScrollAnimation animateIn="zoomIn">
                                <p className="border--right">I'm a self-motivated and ambitious Web Developer, highly focused with a comprehensive knowledge of designing and developing the layout, visual appearance and usability of a website.</p>                             
                            </ScrollAnimation>
                        </div>
                    </div>
                    <div className='content-right'>
                        <div>
                            <ScrollAnimation animateIn="zoomIn">
                                <h1>Florentina Sultan</h1>
                                <span>Frontend Developer</span>
                            </ScrollAnimation>
                            <div className='animated'>
                                <div className="full-width">
                                    <img className="" src={florentina} alt="Sultan Florentina - Frontend Developer"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          );
    }
}

export default Home;