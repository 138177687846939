// @flow
import React, { Component } from 'react';
import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import ScrollAnimation from 'react-animate-on-scroll';
// import resume from '../assets/images/resume.png';
// import cvFlorentinaSultan_resume from '../assets/FlorentinaSultan-resume.pdf';

class Header extends Component {
    constructor(props) {
        super(props);
        this.menuToggle = React.createRef();
        this.state = {
            menuToggleOpen: false,
            isActive: false,
        }
        this.openModal = this.openModal.bind(this);
        this.addActiveClass = this.addActiveClass.bind(this);
    }
    openModal() {
        const menu = document.querySelector('.menu'),
            body = document.querySelector('body'),
            _this = this;
        if (!this.state.menuToggleOpen) {
            menu.classList.remove('out');
            menu.classList.add('open');
            body.classList.add('modal-open');
            this.menuToggle.current.classList.add('open');
            this.setState({menuToggleOpen: true});
        } else {
            menu.classList.add('out');
            body.classList.remove('modal-open');
            setTimeout(function(){
                _this.menuToggle.current.classList.remove('open');
                menu.classList.remove('open')
            },500);
            this.setState({menuToggleOpen: false});
        }    
    }

    addActiveClass(e) {
        const navDesktopItems = e.target;
            document.querySelectorAll('.nav-item-desktop a').forEach((item) =>{
                item.classList.remove('is-active');
            });
            navDesktopItems.classList.add('is-active');
    }

    checkHomepage() {
        const { location } = this.props;
        return (location.pathname === '/') ? 'header sticky homepage' : 'header';
    }

    static propTypes = {
        location: PropTypes.object.isRequired
    };

    render() {
        return (
            <div>
                <header className={this.checkHomepage()}>
                <ScrollAnimation animateIn="zoomIn">
                    <Link className="resume-link" to="/extended-resume">
                        <div className="resume">
                            {/* <img src={resume} className="resume" alt="resume" /> */}
                            <span>See Extended Resume</span>
                        </div>
                    </Link>
                    
                        <ul className="nav-desktop">
                            <li className="nav-item-desktop">
                                <Link onClick={this.addActiveClass} to="/">Home</Link>
                            </li>
                            <li className="nav-item-desktop">
                                <Link onClick={this.addActiveClass} to="/resume">Resume</Link>
                            </li>
                            <li className="nav-item-desktop">
                                <Link onClick={this.addActiveClass} to="/portfolio">Portfolio</Link>
                            </li>
                            <li className="nav-item-desktop">
                                <Link onClick={this.addActiveClass} to="/contact">Contact</Link>
                            </li>
                        </ul>
                    </ScrollAnimation>
                </header>
                <div className="menu-toggle" ref={this.menuToggle} onClick={this.openModal}>
                    <span></span>
                </div>
            </div>
        );
    }
}

export default withRouter(Header);