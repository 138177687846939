import React, { Component } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

class OpenSource extends Component {
    render(){
        return (
            <ScrollAnimation animateIn='zoomIn'>
                <div className="open-source">
                    <h5>{this.props.cardName}</h5>
                    <img src={this.props.cardImage} alt={this.props.cardName}/>
                    <img src={this.props.cardImage2} alt={this.props.cardName}/>
                    <div className="open-source__head">
                        <span>{this.props.cardLinkCMS}</span>
                        <a href={this.props.cardLink} target="_blank" rel="noopener noreferrer">{this.props.cardLinkText}</a>
                    </div>
                </div>
            </ScrollAnimation>
        );
    }
}

export default OpenSource;