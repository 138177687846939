// @flow
import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Header from './Pages/Header';
import Home from './Pages/Home';
import PortfolioPage from './Pages/PortfolioPage';
import Contact from './Pages/Contact';
import Menu from './Pages/Menu';
import Resume from './Pages/Resume';
import ExtendedResume from './Pages/ExtendedResume';
import './assets/css/App.css';

export default function Website() {
  return (
    <Router>
      <Header />
      <div className="main-container">
        <Menu />
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/resume">
            <Resume />
          </Route>
          <Route exact path="/extended-resume">
            <ExtendedResume />
          </Route>
          <Route exact path="/portfolio">
            <PortfolioPage />
          </Route>
          <Route exact path="/contact">
            <Contact />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}